# temporary HXL dictionary
exports.dict = [
    "#activity",
    "#adm1",
    "#adm2",
    "#adm3",
    "#adm4",
    "#adm5",
    "#affected",
    "#beneficiary",
    "#capacity",
    "#cause",
    "#category",
    "#country",
    "#crisis",
    "#date",
    "#description",
    "#geo",
    "#impact",
    "#indicator",
    "#inneed",
    "#loc",
    "#meta",
    "#operations",
    "#org",
    "#output",
    "#population",
    "#reached",
    "#region",
    "#sector",
    "#severity",
    "#subsector",
    "#targeted"
]
